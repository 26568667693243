import React from "react"
import { PageProps } from "gatsby"

import SEO from "Components/core/SEO"
import StandardLayout from "Components/core/StandardLayout"

const NotFoundPage: React.FC<PageProps> = ({ location }) => (
  <StandardLayout location={location}>
    <SEO title="404: Not found" />
    <h1>Sorry, I haven&apos;t created a page for this url</h1>
    <p>Check if its been spelled correctly?</p>
  </StandardLayout>
)

export default NotFoundPage
